$primary: #4c9ed9;
$dark: #000000;
$iconcolor: #006ab5;

$text-link: $primary;

//header
$header-btn-cart: #dc2f2f;
$header-btn-search: #006ab5;
$header-menu-background: #e3e3e3;

//footer
$footer-banner: #006ab5;
$footer-color: #253864;

//sidenavi
$category-icon: $iconcolor;

//button
$btn-font-color: #212529;
$disabled-color: #cccccc;

//carousel
$carousel-active-color: $iconcolor;

//button
$btn-next-color: #ff8640;

//hdg color
$hdg-color: #0f6ab5;

//logo size
$logo_width_sp: 200px;
$logo_width_sm: 200px;
$logo_width_md: 250px;

@import "core";
