@import "node_modules/bootstrap/scss/functions";

@import "variables";

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "header";
@import "footer";
@import "sidebar";
@import "aside_biz";
@import "slick";
@import "pagination";
@import "modal";

html {
  font-size: 14px;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

main {
  flex: 1;
}

a {
  color: $text-link;
}

/*breadcrumb*/
.breadcrumb-item {
  & + .breadcrumb-item {
    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f105";
      color: #cccccc;
    }
  }

  a {
    color: $dark;
  }
}

/*caroucel*/
.carousel {
  padding-bottom: 45px;
}

.carousel-item {
  text-align: center;
}

.carousel-indicators {
  li {
    border-radius: 50%;
    opacity: 1 !important;
    background: #fff;
    border: 1px solid #cccccc;

    &.active {
      border: 1px solid #cccccc;
      background: $carousel-active-color;
    }
  }
}

/*error box*/
.box-error {
  background: #f1f1f1;
  border-radius: 4px;
  padding: 2rem;

  p {
    margin: 1rem 0;
  }
}

.box-success {
  background: lighten($primary, 26%);
  border-radius: 4px;
  padding: 2rem 1rem;

  p {
    margin: 1rem 0;
  }
}

.box-blue {
  background-color: #d7efff;
  border-radius: 10px;
  padding: 1em;

  &.fukidashi-top {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -4rem;
      left: 50%;
      margin-left: -2rem;
      border: 2rem solid transparent;
      border-bottom: 2rem solid #e0edff;
    }
  }
}

/*checkbox add*/
.input-ghost,
.custom-radio {
  padding: 0;

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      color: #fff;
      border-color: $primary;
      background-color: #fff;
    }

    &:after {
      background: $primary;
    }
  }

  label {
    padding: 1rem 1rem 1rem 3rem;

    &:before {
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
    }

    &:after {
      left: 1rem;
      top: 50%;
      transform: translate(0.15rem, -50%);
      width: 0.7rem;
      height: 0.7rem;

      border: none;
      border-radius: 50%;
    }
  }
}

.input-ghost {
  label {
    border: 1px solid #ccc;
  }
}

/*Typograpy*/
.lh-1 {
  line-height: 1;
}

ul,
ol {
  padding-left: 1em;
}

ul {
  &.list-style-disc {
    list-style: disc;
  }

  &.icon-caret {
    list-style: none;
    padding-left: 0;

    li {
      &:before {
        display: inline-block;

        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f0da";
        margin-right: 0.4rem;
      }
    }
  }
}

ol {
  &.list-circle-counter {
    counter-reset: circle-counter;
    list-style: none;
    padding-left: 1.5rem;

    & > li {
      position: relative;

      &:before {
        content: counter(circle-counter);
        counter-increment: circle-counter;
        padding: 0 0.2em;
        margin-right: 0.5em;
        position: absolute;
        display: block;
        top: 0.7em;
        left: -1.5rem;
        transform: translateY(-50%);
      }

      &:after {
        content: "";
        /* 配置を調整 */
        position: absolute;
        display: block;
        top: 0.7em;
        left: -1.5rem;
        transform: translateY(-50%);
        /* ○を作る */
        width: 1em;
        height: 1em;
        border: 1px solid $dark;
        border-radius: 50%;
      }
    }
  }

  &.list-brackets {
    counter-reset: cnt;
    margin-left: 1.5em;
    padding-left: 0;

    & > li {
      list-style-type: none;
      counter-increment: cnt;
      position: relative;

      &:before {
        content: "(" counter(cnt) ")";
        display: inline-block;
        margin-left: -1.5em;
        width: 1em;
        text-align: right;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.head-border {
  padding: 0.5em 0;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 1rem;
}

.navbar {
  border-bottom: 1px solid #eee;
}

.border-l {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #ccc;
  }
}

.table-header {
  caption-side: top;
  background: $dark;
  color: #fff;
  padding: 0.75rem;
  font-size: 1.143rem;
  margin-bottom: 1rem;
}

.bg-black {
  background: $dark;
  color: #fff;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.bg-gray {
  background: #eeeeee;
  color: $dark;
  padding: 1rem;
  margin-bottom: 1rem;
}

.bg-rightblue {
  background: #a5ceec;
}

.line-height-up {
  line-height: 1.6;
}

/*link icon*/
.link-icon__check {
  color: $dark;

  &:before {
    color: $primary;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0da";
    margin-right: 0.4rem;
  }
}

.link-icon__newwindow {
  color: $dark;

  &:after {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f35d";
    margin-left: 0.4rem;
  }
}

/*tables*/
.table-layout-fixed {
  width: 100%;
  table-layout: fixed;
}

.table-lol,
.table-cart,
.table-history,
.table-orderlist,
.table-download,
.table-upload {
  border-bottom: 1px solid #ccc;

  caption {
    caption-side: top;
    background: $dark;
    color: #fff;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 1.143rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }

  thead {
    tr {
      background: #eeeeee;

      th,
      td {
        text-align: center;
        vertical-align: middle;
        line-height: 1.2;

        span {
          font-size: 80%;
        }
      }
    }
  }
}

.table-lol,
.table-cart {
  thead {
    display: none;

    @include media-breakpoint-up(md) {
      display: table-header-group;
    }
  }

  tbody {
    tr {
      &.is-required {
        th {
          &:after {
            display: inline-block;
            font-size: 0.8em;
            padding: 0 0.5em;
            vertical-align: top;
            color: #fff;
            font-weight: bold;
            background-color: #d9534f;
            content: "\5FC5\9808";
            margin-left: 0.5em;
          }

          @include media-breakpoint-up(md) {
            position: relative;

            &:after {
              position: absolute;
              right: 1em;
            }
          }
        }
      }

      th {
        background-color: #eee;
        border-top: 1px solid #ccc;
        text-align: center;

        label {
          margin: 0;
        }
      }

      th,
      td {
        display: block;
        padding: 0.875rem 1rem;

        @include media-breakpoint-up(md) {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          display: table-cell;
          vertical-align: middle;
        }

        &.td__input {
          padding: 0.5rem 1rem;

          span {
            font-size: 80%;
          }

          @include media-breakpoint-up(md) {
            .input-w__25 {
              width: 50%;
            }
          }

          @include media-breakpoint-up(lg) {
            .input-w__50 {
              width: 50%;
            }

            .input-w__25 {
              width: 25%;
            }
          }
        }
      }

      + tr {
        th {
          margin-top: -1px;
        }
      }
    }
  }

  &.is-th-left {
    tbody {
      tr {
        th {
          text-align: left;
        }
      }
    }
  }
}

.table-lol {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;

  tbody {
    th {
      @include media-breakpoint-up(md) {
        width: 16em;
      }

      @include media-breakpoint-up(xl) {
        width: 20em;
      }
    }
  }

  &.is-product {
    tbody {
      th {
        text-align: left;

        @include media-breakpoint-up(md) {
          width: 14em;
        }

        @include media-breakpoint-up(xl) {
          width: 14em;
        }
      }
    }
  }
}

.table-cart {
  @include media-breakpoint-up(md) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  tr {
    @include media-breakpoint-down(sm) {
      display: block;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      margin-bottom: 2rem;
    }

    th,
    td {
      & + th,
      & + td {
        @include media-breakpoint-up(md) {
          border-left: 1px solid #ccc;
        }
      }
    }

    // &.tr-hover {
    //   &.checked {
    //     background: lighten($primary, 45%);
    //   }
    // }
    &.__item {
      border-bottom: 1px solid #ccc;

      td {
        &.__description {
          .media {
            align-items: center;

            img {
              border: 3px solid #eee;
              max-width: 200px;
            }
          }

          .__shipping-group {
            margin-bottom: 0.3em;

            span {
              display: inline-block;
              font-size: 0.8em;
              padding: 0 0.5em;
              vertical-align: middle;
              border: 1px solid #ccc;
              background-color: #fff;
            }
          }

          .__name {
            font-size: 1.071rem;
            font-weight: 400;
            margin: 0 0 0.2rem 0;
            .__name_maker {
              font-weight: 700;
            }
          }

          .__spec {
            .__no,
            .__custom,
            .__notes {
              margin: 0.2rem 0;
            }

            .__no {
              display: flex;
              dt {
                font-weight: normal;
              }
              dd {
                margin: 0 0 0 0.5rem;
              }
            }
            .__custom {
              color: red;
            }
            .__notes {
              font-weight: 700;
            }
          }
        }

        &.__tdheader {
          background: #eee;
          text-align: center;

          @include media-breakpoint-up(md) {
            background: none;
          }
        }

        &.__tditem {
          word-break: break-all;
        }

        &.__no,
        &.__listprice,
        &.__price,
        &.__order,
        &.__total,
        &.__tditem {
          text-align: center;
          display: flex;
          justify-content: space-between;

          &:before {
            content: attr(data-name);
            font-weight: 700;
          }

          @include media-breakpoint-up(md) {
            display: table-cell;

            &:before {
              display: none;
            }
          }
        }

        &.__order {
          .__input,
          .__notice .__min-max-order {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
          }

          .__input {
            input {
              margin-right: 0.3rem;
              flex-basis: 0;
              flex-grow: 1;
              max-width: 100%;
              font-size: 1.143rem;
              padding: 0.3rem 0.5rem;
              text-align: right;

              &.__input-number {
                width: 3em;
              }
            }

            .__spin,
            .__delete {
              flex: 0 0 auto;
              width: auto;
              max-width: none;
              margin: 0 0.3rem;
            }

            .__delete {
              font-size: 0.857rem;
              word-break: keep-all;
              display: flex;
              cursor: pointer;

              &:before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                margin-right: 0.2rem;
                content: "\f2ed";
                color: #999999;
              }
            }
          }

          .__notice {
            font-size: 0.857rem;
            text-align: center;
            margin-top: 0.5em;

            dt,
            dd {
              margin: 0;
              padding: 0;
            }

            .__added {
              ._added-num {
                background: #2ab27b;
                color: #fff;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 1.5em;
                width: 1.5em;
                margin: 0.3em;
              }
            }
          }
        }
      }
    }
  }

  &.is-product {
    tr.__item td.__description .__spec .__no {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding: 0.5em 0;
      margin: 0.5em 0;
      dt {
        font-weight: normal;
      }
    }

    tbody {
      .__item {
        .__description {
          max-width: 600px;
        }
      }
    }
  }
}

.table-history,
.table-orderlist {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  text-align: center;
  min-width: 600px;

  @include media-breakpoint-up(lg) {
    table-layout: fixed;
  }

  th,
  td {
    vertical-align: middle;
  }

  tr {
    th {
      & + th,
      & + td {
        border-left: 1px solid #ccc;
      }
    }

    td {
      & + td {
        border-left: 1px solid #ccc;
      }
    }

    + tr {
      border-top: 1px dashed #ccc;
    }
  }
}

.table-orderlist {
  border-left: none;

  thead {
    tr {
      background: none;

      th {
        &:not(.is-blank) {
          background: $dark;
          color: #fff;
          padding: 1rem;
        }

        &.is-blank {
          border-top: none;
          width: 12em;
        }
      }
    }
  }

  tbody {
    text-align: left;

    tr {
      th {
        background: #eeeeee;
        width: auto;
        position: relative;

        &:before {
          content: "";
          height: 100%;
          display: block;
          border-left: 1px solid #ccc;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.table-contractlist {
  &.table-topborder {
    border-top: 1rem solid #000;
  }
  tr {
    th {
      border-left: 1px solid #ccc;
    }

    &.bg-brightblue {
      background: #cfe5f5;
    }
  }

  tbody {
    tr {
      th {
        background: #eeeeee;
        width: auto;
        position: relative;

        &:before {
          content: "";
          height: 100%;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }

        &.is-th-white {
          background: #fff;
          font-weight: normal;
        }
      }

      &.productname td {
        text-align: left;
        .__name {
          font-size: 1.071rem;
          font-weight: 400;
          margin: 0 0 0.2rem 0;
          .__name_maker {
            font-weight: 700;
          }
        }

        .__spec {
          .__no {
            display: flex;
            dt {
              font-weight: normal;
            }
            dd {
              margin: 0 0 0 0.5rem;
            }
          }
        }
      }
    }
  }
}

.table-download,
.table-upload {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  tr {
    td {
      display: block;
      padding: 0 1rem 0.875rem 1rem;

      &:before {
        content: attr(data-name);
        font-weight: 700;
        display: block;
        background: #eeeeee;
        margin-right: -1rem;
        margin-left: -1rem;
        margin-bottom: 0.875rem;
        padding: 0.875rem 1rem;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        display: table-cell;
        vertical-align: middle;
        padding-top: 0.875rem;
        text-align: center;

        &.__name,
        &.__file {
          text-align: left;
        }

        & + td {
          border-left: 1px solid #ccc;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      display: block;

      td {
        min-height: 6rem;
      }
    }
  }

  thead {
    @include media-breakpoint-down(md) {
      display: none;
    }

    tr {
      td {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

.table-download {
  tr {
    th,
    td {
      &.__name {
        .__name_maker {
          font-weight: 700;
        }

        .__spec {
          .__no {
            display: flex;
            dt {
              font-weight: normal;
            }
            dd {
              margin: 0 0 0 0.5rem;
            }
          }
        }
      }
      &.td__input {
        padding: 0.5rem 1rem;

        @include media-breakpoint-up(lg) {
          .input-w__25 {
            width: 50%;
          }
        }

        @include media-breakpoint-up(lg) {
          .input-w__50 {
            width: 50%;
          }

          .input-w__25 {
            width: 25%;
          }
        }
      }
    }

    + tr {
      th {
        margin-top: -1px;
      }
    }

    td {
      @include media-breakpoint-up(lg) {
        &.__no {
          width: 8em;
        }

        &.__description {
          width: 24em;
        }

        &.__download {
          width: 16em;
          text-align: center;
        }
      }
    }
  }
}

.table-upload {
  tr {
    td {
      @include media-breakpoint-up(lg) {
        &.__no {
          width: 4em;
          text-align: center;
        }

        &.__date {
          width: 12em;
          text-align: center;
        }
      }
    }
  }
}

.table-foot-desc {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 1rem;

  &.top {
    border-top: 1px solid #ccc;
    border-bottom: none;
    margin: 0;
  }
}

/* --------------------------------------------------------------------------
   数量増減ボタン  .p-spin
   -------------------------------------------------------------------------- */
.p-spin {
  display: flex;

  .__minus,
  .__plus {
    border-radius: 0;
    background-color: #f2f2f2;
    padding: 0.5em;
    line-height: 1em;
    border: 1px solid #ccc;
    vertical-align: middle;
    text-align: center;
    width: 2.5em;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
    font-family: san-serif;

    &:active {
      box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.15) inset,
        0 0 6px rgba(0, 0, 0, 0.2) inset;
      border-color: $dark;
    }

    @include media-breakpoint-up(md) {
      width: 2em;
    }
  }

  .__minus {
    border-right: none;

    &:before {
      content: "-";
    }
  }

  .__plus {
    &:before {
      content: "+";
    }
  }
}

/*links*/
.img-product {
  display: block;
  position: relative;

  img {
    border: 3px solid #eee;
    max-width: 100%;
    object-fit: contain;
    width: 100%;
    aspect-ratio: 3 / 2;
    box-sizing: content-box;
  }

  p {
    margin-top: 0.5rem;
  }

  &:hover {
    img {
      border-color: $primary;
    }
  }
}

/* buttons */
.btn {
  //color: $btn-font-color;

  &.small {
    padding: 0.5rem 1rem;
  }
}

.btn-ghost {
  border: 1px solid $disabled-color;
  padding: 1rem;
  background: #fff;

  &:hover {
    background: darken(#fff, 10%);
  }

  &.is-add {
    padding: 0.5rem 1rem;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f067";
      margin-right: 0.5rem;
    }
  }

  &.is-delete {
    padding: 0.5rem 1rem;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f2ed";
      margin-right: 0.5rem;
    }
  }
}

.btn-next {
  border: 1px solid $disabled-color;
  background: $btn-next-color;
  padding: 1rem;
  color: #fff;

  &:hover {
    color: #fff;
    background: lighten($btn-next-color, 10%);
  }
}

.btn-download {
  background: $primary;
  color: #fff;
  padding: 1rem;

  &:hover {
    background: darken($primary, 10%);
    color: #fff;
  }
}

.btn-regist__circle {
  background: $primary;
  color: #fff;
  padding: 0.7rem 1.5rem;

  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f303";
    background: #fff;
    font-size: 1rem;
    height: 2rem;
    width: 2rem;
    line-height: 1;
    display: inline-block;
    color: $iconcolor;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  &:hover {
    background: darken($primary, 10%);
    color: #fff;
  }
}

.btn-pdf {
  border: 1px solid #cccccc;
  line-height: 1;
  padding: 1rem 1rem;
  border-radius: 1.5rem;
  background: #fff;

  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f019";
    margin-right: 0.5rem;
  }

  &:hover {
    background: darken(#fff, 10%);
  }
}

.btn-cart {
  border: 1px solid #cccccc;
  line-height: 1;
  padding: 1rem 3rem;
  background: #ff8640;
  color: #fff;

  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f07a";
    margin-right: 0.5rem;
  }

  &:hover {
    color: #fff;
    background: darken(#ff8640, 10%);
  }
}

.btn-gray {
  border: 1px solid #cccccc;
  line-height: 1;
  padding: 1rem 3rem;
  background: #eee;

  &:hover {
    background: darken(#eee, 10%);
  }
}

.btn-blue {
  border: 1px solid $primary;
  line-height: 1;
  padding: 1rem 3rem;
  background: $primary;
  color: #fff;

  &.is-add {
    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f067";
      margin-right: 0.5rem;
    }
  }

  &.is-button {
    line-height: 1.5;
  }

  &:hover {
    color: #fff;
    background: darken($primary, 10%);
  }
}

/*pagetotop*/
.pagetop {
  cursor: pointer;
  transform: translateY(100%);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    height: 50px;
    width: 50px;
    transition: all 0.3s ease;

    &:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f106";
      font-size: 2rem;
      transition: all 0.3s ease;
    }

    &:hover {
      text-decoration: none;

      &:after {
        transform: translateY(-6px);
      }
    }
  }

  &.show {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}

/*ニュース*/
.news {
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem;

  dt {
    width: 7em;
  }

  dd {
    width: calc(100% - 7em);
    &.new:after {
      content: "NEW";
      color: #fff;
      display: inline-block;
      background: #d9534f;
      font-size: 0.857rem;
      padding: 0.3em;
      font-weight: bold;
      line-height: 1;
      margin: 0 0.5rem;
      vertical-align: text-bottom;
    }
  }
}

/*カート*/
.cart-overview {
  .cart-overview-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark;
    padding: 1rem;

    .cart-overview-item__title {
      width: 4em;
      text-align: center;
    }
  }
}

.cart-overview-btn {
  margin: 2rem 0;
  justify-content: center;

  a {
    display: block;
    text-align: center;
    margin: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    justify-content: flex-end;

    a {
      margin-right: 0;
    }
  }
}

.biz-merit {
  .biz-ttl {
    color: $primary;
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: bold;
    position: relative;
    margin-top: 5rem;
    margin-bottom: 3rem;

    &:after {
      content: "";
      background: url(/img/ribbon.png) no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      width: 100%;
      height: 10px;
      z-index: 0;
    }

    .biz-ttl-inner {
      padding: 0 20px;
      background-color: #fff;
      display: inline-block;
      padding: 0 10px;
      position: relative;
      z-index: 1;
    }

    .biz-ttl-sub {
      display: block;
      font-weight: normal;
      font-size: 1.6rem;
    }
  }

  .biz-merit-content {
    margin: 3rem 0;
    padding: 2rem;
    background-color: #f0f4fc;
    position: relative;

    .biz-merit-item {
      padding-top: 2rem;
      margin: 1rem 0;
    }
  }

  .list-thumb-item {
    padding: 1rem;
    background-color: #fff;

    .thumb-ttl {
      font-weight: bold;
    }

    .thumb-description {
      margin-top: 2rem;
    }

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 15px;
      border-style: solid;
      border-color: transparent transparent #f0f4fc #d9d9d9;
      border-width: 0 0px 20px 20px;
    }
  }
}

/*ballon*/
.balloon {
  background-color: #ff8640;
  border-radius: 50%;
  color: #fff;
  font-size: 0.857rem;
  position: absolute;
  top: 10px;
  left: 5px;
  text-align: center;
  height: 60px;
  width: 60px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 1px;
    right: 1px;
    border: 5px solid transparent;
    border-left: 5px solid #ff8640;
    z-index: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .balloon-inner {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 100%;
  }

  .balloon-num {
    font-size: 1.143rem;
    font-weight: bold;
    margin-left: 0.1em;
  }

  @include media-breakpoint-up(md) {
    top: 0px;
    left: 5px;
    height: 75px;
    width: 75px;

    &:after {
      bottom: -8px;
      right: -8px;
      border: 15px solid transparent;
      border-left: 15px solid #ff8640;
    }

    .balloon-num {
      font-size: 1.286rem;
    }
  }
}

/*stepbar*/
.stepBar {
  display: flex;
  max-width: 400px;
  position: relative;
  margin: 2rem auto;
  text-align: center;
  padding: 0;

  @include media-breakpoint-up(md) {
    margin: 5rem auto;
    max-width: 600px;
  }

  li {
    font-size: 0.857rem;
    list-style: none;
    position: relative;
    width: 33.333%;

    &:after {
      font-size: 1.429rem;
      background: #999999;
      content: "";
      width: calc(100% - 3em);
      height: 4px;
      position: absolute;
      left: calc(-50% + 1.5em);
      top: calc(1.5em - 2px);
    }

    &:first-child:after {
      display: none;
    }

    span {
      background: #999999;
      color: #ffffff;
      display: inline-block;
      height: 3em;
      font-size: 1.429rem;
      margin-bottom: 5px;
      line-height: 3em;
      width: 3em;
      border-radius: 50%;
    }
  }

  .visited {
    &:after {
      background: #333333;
    }

    span {
      background: #333333;
    }
  }
}

/*order page*/

.input-ghost {
  &.input-order {
    label {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 28em;
      }

      text-align: center;
      padding-right: 3rem;
    }
  }
}

a {
  &.input-order {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 28em;
    }

    padding: 1rem;
  }
}

.card__orderid {
  padding: 1rem 4rem;
  display: inline-block;
  margin: 3rem 0;
  font-size: 1.429rem;

  .card-body {
    span {
      color: #0099ff;
    }
  }
}

/*product*/
.product {
  .product-image {
    display: block;
    text-align: center;
    margin-bottom: 1rem;

    img {
      border: 3px solid #eeeeee;
      max-width: 100%;
      object-fit: contain;
      width: 100%;
      aspect-ratio: 3 / 2;
      box-sizing: content-box;
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .product-onepoint {
    font-weight: bold;
  }
}

.makerlist {
  list-style: none;
  padding: 0;
  margin: 2rem 0;

  li {
    display: inline-block;

    & + li {
      padding-left: 1em;
      margin-left: 1em;
      border-left: 1px solid #ccc;
    }

    a {
      color: $dark;
    }
  }
}

.input-flex {
  display: flex;
  align-items: center;

  label {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin: 0;
  }

  span {
    margin: 0 0.4rem;
  }

  .w-date {
    width: 10em;
  }

  .input-w__mailfront {
    width: 35%;
    max-width: 15em;
  }

  .input-w__mailback {
    width: calc(65% - 2rem);
    max-width: 30em;
  }
}

.search {
  background: #eeeeee;

  .search-header {
    background: $dark;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1rem;
      padding: 1rem;
      margin: 0;
    }

    .btn {
      background: #fff;
      color: $dark;
      margin: 0.3rem 1rem;

      &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        margin-right: 0.3rem;
      }

      &:hover {
        background: darken(#fff, 10%);
      }
    }
  }

  .btn-ghost {
    background: #fff;
  }

  .search-body {
    padding: 1rem;

    @include media-breakpoint-up(md) {
      padding: 1.5rem 2rem;
    }

    label {
      font-weight: bold;
    }

    .date-between {
      display: flex;
      align-items: center;

      span {
        margin: 0 0.4rem;
      }
    }
  }
}

.form-tag {
  border: 1px solid $disabled-color;
  border-radius: 4px;
  padding: 1rem;
  margin: 0.5rem;
  color: #6e6e6e;
  min-width: 10em;
  text-align: center;

  &:hover {
    background: darken(#fff, 5%);
  }

  &.disabled {
    background: $disabled-color;
    color: #fff;
    pointer-events: none;
  }
}

.license-header {
  background: $dark;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 1.143rem;
    padding: 1rem;
    margin: 0;
  }

  .btn {
    background: #fff;
    color: $dark;
    margin: 0.6rem 1rem;
    margin-right: 1rem;
    padding: 0.5rem 3rem;
  }
}

.license-certificate {
  margin: 3rem 0;

  .license-header {
    .btn {
      &:hover {
        background: darken(#fff, 10%);
      }
    }
  }

  .license-number {
    margin: 1.5rem 0;
    border: 3px solid $primary;
    padding: 1rem;
    border-radius: 3px;
    font-size: 1.286rem;
    font-weight: bold;
  }

  .license-section {
    display: flex;
    flex-wrap: nowrap;
  }

  .license-btn {
    background: #999999;
    border: 1px solid #cccccc;
    flex: 0 0 auto;
    width: 8em;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      background: #fff;
      border-radius: 5px;
      text-align: center;
      padding: 0.5rem 1.5rem;

      &:hover {
        background: darken(#fff, 20%);
      }
    }
  }

  .license-table-wrap {
    .table-license {
      margin-bottom: 0;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      min-width: 600px;
      table-layout: fixed;

      tbody {
        tr {
          th,
          td {
            vertical-align: middle;
            word-break: break-all;
            width: 20em;

            @include media-breakpoint-up(lg) {
              width: auto;
            }

            label {
              margin: 0;
            }

            & + th,
            & + td {
              border-left: 1px solid #ccc;
            }

            &.td-number {
              width: 8em;

              & + .td-number {
                width: 15em;
              }
            }
          }

          th {
            background: #eeeeee;
            text-align: center;

            &.table-license-header {
              width: 12em;
            }
          }
        }
      }
    }
  }
}

.file-section {
  display: flex;
  align-items: center;

  .file-btn {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;

    a {
      display: block;
      border: 1px solid #cccccc;
      border-radius: 4px;
      margin: 0.3rem;
      line-height: 1;

      &:hover {
        background: darken(#fff, 5%);
      }

      &:disabled,
      &.disabled {
        background: $disabled-color;
        color: #fff;
        pointer-events: none;
      }

      &.is-large {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  .file-name {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.sticky-cart {
  position: sticky;
  top: 1rem;
}

.sticky-bottom {
  padding-bottom: 5rem;
}

.position-relative + .l-pagination {
  margin-top: 0;
}

.link-campaign {
  display: block;
  color: $dark;
  padding: 2rem 1rem;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem auto;
  }

  .campaign-body {
    color: $dark;
    margin: 1rem auto;

    h2 {
      color: #0099ff;
      text-decoration: underline;
      font-size: 1.429rem;
      font-weight: bold;
    }
  }

  &:hover {
    background: darken(#fff, 5%);
    text-decoration: none;

    .campaign-body {
      h2 {
        text-decoration: none;
      }
    }
  }

  &.new {
    h2 {
      &:after {
        content: "NEW";
        color: #fff;
        display: inline-block;
        background: #d9534f;
        font-size: 0.857rem;
        padding: 0.3rem;
        font-weight: bold;
        line-height: 1;
        margin: 0 0.5rem;
        vertical-align: middle;
      }
    }
  }
}

/*faq*/
.faq-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  @include make-row();

  li {
    @include make-col-ready();
    @include make-col(6);

    @include media-breakpoint-up(lg) {
      @include make-col(3);
      text-align: right;
    }

    a {
      display: inline-block;
      word-break: keep-all;
      color: $dark;

      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        margin-left: 0.4rem;
        display: inline-block;
        color: $primary;
      }
    }
  }
}

.faq-section {
  margin: 3rem 0;

  h1 {
    font-size: 1.429rem;
    border-bottom: 1px solid #cccccc;
    padding: 0.5em 0;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      background: url(/img/icon-question-balloon.svg) no-repeat;
      background-size: 100%;
      width: 25px;
      height: 25px;
      margin-right: 0.5rem;
    }
  }

  .collapse-link {
    padding: 0.5rem;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark;

    &:hover {
      background: #f1f1f1;
      text-decoration: none;
    }

    &:after {
      margin-left: 0.5em;
      font-size: 1.25em;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f055";
      color: $iconcolor;
    }

    &[aria-expanded="false"] {
      &:after {
        content: "\f055";
      }
    }

    &[aria-expanded="true"] {
      &:after {
        content: "\f056";
      }
    }
  }

  .faq-item__body {
    margin: 1rem 0;
    padding: 1rem 2rem;
    background: #f1f1f1;

    p {
      margin: 1rem 0;
    }
  }
}

.area-edit {
  padding: 2rem 1rem;
  border-bottom: 1px dashed #a8a5a6;

  a {
    color: #0099ff;
    text-decoration: underline;

    &:before {
      color: #333333;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

/*style_staticより移植*/
.hdg-lv1 {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 26px;

  @media screen and (min-width: 769px) {
    margin-top: 17px;
  }

  & + section.has-box--01 .content {
    margin-top: 50px;

    @media screen and (min-width: 769px) {
      padding-top: 140px;
      margin-top: 93px;
    }

    @media screen and (max-width: 768px) {
      padding-top: 50px;
    }
  }

  // & + section.has-box--01 .content {
  // 	@media screen and (max-width: 768px) {
  // 		padding-top: 50px;
  // 	}
  // }
}

.hdg-lv2 {
  & span {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 2px;
    background: url(../img/h2-line.png) left bottom/1px 10px repeat-x;

    @media screen and (min-width: 769px) {
      font-size: 26px;
      letter-spacing: 3px;
    }
  }

  & + .hdg-lv3 {
    margin-top: 25px;

    @media screen and (min-width: 769px) {
      margin-top: 61px;
    }
  }

  & + p {
    font-size: 16px;
    line-height: 2;

    &.txt-common {
      margin-left: auto;
      margin-right: auto;
      max-width: 783px;
    }
  }

  &--primary {
    margin-top: 80px;

    @media screen and (min-width: 769px) {
      margin-top: 100px;
    }
  }
}

.hdg-lv3 {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.025em;
  padding: 14px;
  border-top: 1px solid $hdg-color;
  border-bottom: 1px solid $hdg-color;

  @media screen and (min-width: 769px) {
    font-size: 26px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 25px;
  }

  & + .hdg-lv4 {
    margin-top: 41px;

    @media screen and (max-width: 768px) {
      margin-top: 25px;
    }
  }

  &--01 {
    text-align: center;
    margin: 25px 0 25px;

    @media screen and (min-width: 769px) {
      margin: 54px 0 46px;
    }
  }

  &--primary {
    font-size: 17px;
    line-height: 1.6;
    font-weight: 700;
    text-align: center;
    border: none;
  }

  &--secondary {
    margin-top: 30px;
  }

  &--sp {
    @media screen and (max-width: 768px) {
      margin: 25px 0 0 !important;
    }

    & + img.sp-center {
      @media screen and (max-width: 768px) {
        margin-top: 40px;
      }
    }

    & + p {
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        margin-top: 25px;
      }
    }
  }
}

.hdg-lv4 {
  padding: 1px 0 0 12px;
  font-size: 18px;
  line-height: 1.45;
  border-left: 6px solid $hdg-color;
  color: #000000;

  @media screen and (min-width: 769px) {
    font-size: 20px;
  }

  &--primary {
    margin-top: 50px;

    @media screen and (min-width: 769px) {
      margin-top: 80px;
    }
  }

  &.hdg-lv4--secondary {
    display: inline-block;
    padding: 14px 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    border-left: none;
  }

  & + .hdg-lv4--secondary {
    margin-top: 42px;
  }
}

.box-consult__content {
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
    box-sizing: border-box;
  }

  .box-consult__list > div {
    margin: 0 0 38px;
  }

  .grid-item {
    position: relative;
    width: 100%;
    padding: 0 20px;
    color: #333333;
    box-sizing: border-box;

    @media screen and (min-width: 769px) {
      width: 33.33%;
    }
  }

  .box-consult__item {
    background-color: #fff;
    padding: 2px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;

    .hdg-lv3_title-02 {
      @media screen and (min-width: 769px) {
        font-size: 26px;
      }

      font-size: 20px;
      letter-spacing: -0.025em;
      padding: 35px 0 24px 0;
    }

    &__text {
      p {
        font-size: 16px;
        letter-spacing: -0.025em;
        line-height: 2;
      }
    }

    &__image {
      margin: 0;

      img {
        position: unset;
        left: 0;
        transform: unset;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    &__image-02 img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 33%;
      left: unset;
      transform: unset;
    }

    &__text {
      & + .mt-25 {
        @media screen and (min-width: 769px) {
          margin-top: 25px !important;
        }

        margin-top: 12px !important;
      }
    }
  }

  .col-6--md {
    @media screen and (min-width: 769px) {
      width: 66.66%;
    }

    .box-consult__item__image {
      margin-bottom: 70px;

      @media screen and (max-width: 768px) {
        width: 63%;
        max-width: 60%;
      }

      & + .box-consult__item__text {
        margin-top: 25px;
      }
    }
  }
}

.hdg-lv3_title-02 {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
  text-align: center;
  border: none;
}

.hdg-lv3_title-03 {
  font-size: 17.5px;
  line-height: 1.6;
  font-weight: 700;
  color: $hdg-color;
  text-align: center;
  letter-spacing: 1px;
}

.fluid-image {
  width: 100%;
  height: auto;
}

q,
blockquote {
  quotes: none;
}

.button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 275px;
  min-height: 60px;
  padding: 10px 20px;
  margin: 30px auto 0;
  background: #ffeb2f;
  border-radius: 10px;
  letter-spacing: 1px;

  @media screen and (min-width: 769px) {
    width: 400px;
    min-height: 80px;
    padding: 10px 40px;
    margin-top: 50px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -15px;
    top: 50%;
    width: 30px;
    height: 12px;
    background: url(../img/icon-arrow-black.svg) center center/contain no-repeat;
    pointer-events: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;

    @media screen and (min-width: 769px) {
      right: -20px;
      width: 40px;
      height: 14px;
      transform: translate(0, -50%);
      transition: transform 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }

  &:hover::after {
    @media screen and (min-width: 769px) {
      transform: translate(35%, -50%);
    }
  }

  &:hover {
    text-decoration: none !important;
  }

  & span {
    font-size: 16px;
    color: #000;
    line-height: 1.5;
    letter-spacing: -0.025em;
    text-align: center;

    @media screen and (min-width: 769px) {
      font-size: 18px;
    }

    @media all and (-ms-high-contrast: none) {
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
  }

  &--primary {
    margin: 45px 0 0;
  }

  &--secondary {
    @media screen and (min-width: 769px) {
      align-items: unset;
      padding: 25px 0 0;
      height: 88px;
    }

    @media all and (-ms-high-contrast: none) {
      padding: 5px 0 0;
    }

    &::after {
      top: inherit;
      right: 50%;
      bottom: -15px;
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
      width: 12px;
      height: 30px;
      background: url(../img/icon-arrow-down-black.svg) center center/contain no-repeat;

      @media screen and (min-width: 769px) {
        bottom: -21px;
        width: 18px;
        height: 42px;
      }
    }

    &:hover::after {
      @media screen and (min-width: 769px) {
        -webkit-transform: translate(50%, 35%);
        transform: translate(50%, 35%);
      }
    }
  }

  &--third {
    @media screen and (min-width: 769px) {
      display: inline-block;
      margin-top: 133px;
      width: auto;
      padding: 0 85px;
    }

    &::after {
      right: inherit;
      left: -20px;
      -webkit-transform: rotate(180deg) translateY(50%);
      transform: rotate(180deg) translateY(50%);
    }

    &:hover::after {
      @media screen and (min-width: 769px) {
        -webkit-transform: rotate(180deg) translate(35%, 50%);
        transform: rotate(180deg) translate(35%, 50%);
      }
    }

    & span {
      display: flex;
      align-items: center;
      height: 100%;

      @media all and (-ms-high-contrast: none) {
        position: static;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    &--v2 {
      @media screen and (min-width: 769px) {
        padding: 0 30px 0 85px;
      }

      & span {
        @media screen and (max-width: 768px) {
          font-size: 14.4px;
        }
      }
    }
  }

  &--fifth {
    @media screen and (min-width: 769px) {
      padding: 10px 0;
    }

    & span {
      line-height: 1.1;

      @media screen and (min-width: 769px) {
        line-height: 1.5;
      }
    }
  }

  &--sixth {
    max-width: 345px;
  }

  &--white {
    background: #ffffff;
  }

  &--border {
    background: #ffffff;
    border: 3px solid #ffeb2f;
  }

  &.w42per {
    @media screen and (max-width: 768px) {
      width: 275px !important;
    }
  }

  & + .txt-common {
    margin-top: 10px;
  }
}

.wrp-button {
  margin-top: 83px;

  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
  }

  @media screen and (max-width: 1247px) {
    margin-left: 0;
    margin-right: 0;
  }

  & .button--secondary {
    @media screen and (min-width: 769px) {
      margin: 0 20px;
    }

    & span {
      @media screen and (max-width: 1200px) and (min-width: 769px) {
        font-size: 1.5vw;
      }
    }
  }

  & .button {
    @media screen and (min-width: 769px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  & + .hdg-lv3--line {
    @media screen and (max-width: 768px) {
      margin-top: 80px !important;
    }
  }

  &--01 {
    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }
  }
}

.hdg-lv2 + .wrp-button {
  margin-top: 12.5px;
}
